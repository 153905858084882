
  import { Component, Vue } from "vue-property-decorator";
  import { appName } from "@/env";
  import { dispatchPasswordRecovery } from "@/store/main/actions";
  import { required } from "vee-validate/dist/rules";
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

  // register validation rules
  extend("required", { ...required, message: "{_field_} can not be empty" });

  @Component({
    components: {
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class Login extends Vue {
    $refs!: {
      observer: InstanceType<typeof ValidationObserver>;
    };

    public valid = true;
    public username = "";
    public appName = appName;

    public cancel() {
      this.$router.back();
    }

    public async onSubmit() {
      const success = await this.$refs.observer.validate();
      if (!success) {
        return;
      }

      dispatchPasswordRecovery(this.$store, { username: this.username });
    }
  }
